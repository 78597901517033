import React from "react";
import TitleBar from "./parts/TitleBar";
import IllustArray from "./parts/IllustArray";

const Illust = () => {
  return (
    <div>
      <TitleBar />
      <div className="flex justify-center items-start h-screen ">
        <div className="grid sm:grid-cols-6 grid-cols-2 sm:gap-6 bg-cyan-100 sm:p-6 p-1 aspect-ratio">
          <IllustArray />
        </div>
      </div>
    </div>
  );
};

export default Illust;
