import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

const MsgArray = () => {
  const msgImage = [
    "/img/message/12指腸_ネモた.jpg",
    "/img/message/13bird_ネモた.jpg",
    "/img/message/atforce _ネモた.jpg",
    "/img/message/Cynops_ネモた.jpg",
    "/img/message/DesperateSkeleton_ネモた.jpg",
    "/img/message/ECHO_ネモた.jpg",
    "/img/message/gonou_ネモた.jpg",
    "/img/message/H_M_ネモた.jpg",
    "/img/message/Henry Glay Work_ネモた.jpg",
    "/img/message/Hikomuro_ネモた.jpg",
    "/img/message/KAZUN_ネモた.jpg",
    "/img/message/KYO_ネモた.jpg",
    "/img/message/Niharu_ネモた.jpg",
    "/img/message/Runa_ネモた.jpg",
    "/img/message/Shint_ネモた.jpg",
    "/img/message/strayPine_ネモた.jpg",
  ];

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (index: number) => {
    setSelectedImage(msgImage[index]);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const modalAnimation = useSpring({
    opacity: selectedImage ? 1 : 0,
    transform: `scale(${selectedImage ? 1 : 0.5})`,
  });

  return (
    <>
      {msgImage.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`msgImage ${index}`}
          className="shadow transform hover:scale-105 transition-transform duration-300 cursor-pointer"
          onClick={() => openModal(index)}
        />
      ))}

      {selectedImage && (
        <animated.div
          style={modalAnimation}
          className="fixed top-0 left-0 w-full h-full  flex justify-center items-center"
          onClick={closeModal}
        >
          <img
            src={selectedImage}
            alt={`selectedImage ${msgImage}`}
            className=" h-1/2"
          />
        </animated.div>
      )}
    </>
  );
};

export default MsgArray;
