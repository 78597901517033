import React from "react";
import TitleBar from "./parts/TitleBar";
import MsgArray from "./parts/MsgArray";

const Message = () => {
  return (
    <div className="">
      <TitleBar />
      <div className="flex justify-center items-start h-screen ">
        <div className="grid sm:grid-cols-6 grid-cols-2 sm:gap-6 gap-1 bg-cyan-100 sm:p-6 p-1 ">
          <MsgArray />
        </div>
      </div>
    </div>
  );
};

export default Message;
