import React from "react";

const TitleBar = () => {
  return (
    <a href="/Home">
      <div className="sticky top-0 overscroll-contain  bg-sky-700 h-16 shadow z-50 flex text-justify items-center text-white pl-6">
        ネモ誕2023
      </div>
    </a>
  );
};

export default TitleBar;
