import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Start from "./component/Start";
import "./App.css";
import Home from "./component/Home";
import Illust from "./component/Illust";
import Message from "./component/Message";
import TimeLine from "./component/TimeLine";

function App() {
  return (
    <div className="bg-cyan-100 h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Illust" element={<Illust />} />
          <Route path="/Message" element={<Message />} />
          <Route path="/TimeLine" element={<TimeLine />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
