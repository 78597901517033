import React, { useState } from "react";

const IllustArray = () => {
  const illustImage = [
    "/img/illust/Illust_01.jpg",
    "/img/illust/Illust_02.jpg",
    "/img/illust/Illust_03.jpg",
    "/img/illust/Illust_04.jpg",
    "/img/illust/Illust_05.jpg",
    "/img/illust/Illust_06.jpg",
    "/img/illust/Illust_07.jpg",
    "/img/illust/Illust_08.jpg",
    "/img/illust/Illust_09.jpg",
    "/img/illust/Illust_10.jpg",
    "/img/illust/Illust_11.jpg",
    "/img/illust/Illust_12.jpg",
    "/img/illust/Illust_13.jpg",
    "/img/illust/Illust_14.jpg",
    "/img/illust/Illust_15.jpg",
    "/img/illust/Illust_16.jpg",
    "/img/illust/Illust_17.jpg",
    "/img/illust/Illust_18.jpg",
    "/img/illust/Illust_19.jpg",
    "/img/illust/Illust_20.jpg",
    "/img/illust/Illust_21.jpg",
    "/img/illust/Illust_22.jpg",
    "/img/illust/Illust_23.jpg",
    "/img/illust/Illust_24.jpg",
    "/img/illust/Illust_25.jpg",
    "/img/illust/Illust_26.jpg",
    "/img/illust/Illust_27.jpg",
    "/img/illust/Illust_28.jpg",
    "/img/illust/Illust_29.jpg",
    "/img/illust/Illust_30.jpg",
  ];
  const samuneImage = [
    "/img/illust/samune_01.jpg",
    "/img/illust/samune_02.jpg",
    "/img/illust/samune_03.jpg",
    "/img/illust/samune_04.jpg",
    "/img/illust/samune_05.jpg",
    "/img/illust/samune_06.jpg",
    "/img/illust/samune_07.jpg",
    "/img/illust/samune_08.jpg",
    "/img/illust/samune_09.jpg",
    "/img/illust/samune_10.jpg",
    "/img/illust/samune_11.jpg",
    "/img/illust/samune_12.jpg",
    "/img/illust/samune_13.jpg",
    "/img/illust/samune_14.jpg",
    "/img/illust/samune_15.jpg",
    "/img/illust/samune_16.jpg",
    "/img/illust/samune_17.jpg",
    "/img/illust/samune_18.jpg",
    "/img/illust/samune_19.jpg",
    "/img/illust/samune_20.jpg",
    "/img/illust/samune_21.jpg",
    "/img/illust/samune_22.jpg",
    "/img/illust/samune_23.jpg",
    "/img/illust/samune_24.jpg",
    "/img/illust/samune_25.jpg",
    "/img/illust/samune_26.jpg",
    "/img/illust/samune_27.jpg",
    "/img/illust/samune_28.jpg",
    "/img/illust/samune_29.jpg",
    "/img/illust/samune_30.jpg",
  ];

  const link = [
    "https://youtube.com/shorts/D6bAdYRaQ-o?si=Rh-TcQWrxxBsf2Wz",
    "https://youtube.com/shorts/-ZVrLk-B26c?si=Sv8OzI9DLVV0vT6C",
    "https://youtube.com/shorts/wkflxpGYP5M?si=KgolGWaC1pqwoKQx",
    "https://youtube.com/shorts/aWI_kJdAfJY?si=XKu6YSGL1QGM6J5x",
    "https://youtube.com/shorts/MvljHVdvIPI?si=WM25JpraHjf_HnUJ",
    "https://youtube.com/shorts/ZCK0GtU8n4I?si=odk8_OnsTSQBz1M4",
    "https://youtube.com/shorts/AfxUm7B0WIU?si=3bMwbGzhKsRtGNYb",
    "https://youtube.com/shorts/mo3KRSwZcXM?si=hOdLDLCpkQVAN0v5",
    "https://youtube.com/shorts/zjr-4NCZ5pc?si=_2D3rAT7MbzbsDfB",
    "https://youtube.com/shorts/e4aq7GGHu5g?si=arMqc-LdxAuZUbyh",
    "https://youtube.com/shorts/_EI4x6N8udM?si=D0_Evahl_cMkqgb8",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
    "https://youtube.com/shorts/5I9mIhLnJt4?si=A-m1-WEZ4Nv6qrAI",
  ];

  const name = [
    "キュートなカノジョ",
    "金木犀 ",
    "可愛いあの子が気にゐらない",
    "いのちの名前",
    "ボルテッカー",
    "クラクラ",
    "カワキヲアメク",
    "嘘月",
    "Happy Halloween",
    "少女A ",
    "撫でんな",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
    "猛独が襲う",
  ];

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (index: number) => {
    setSelectedImage(illustImage[index]);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const youtubeLink = (index: number) => {
    window.location.href = link[index];
  };

  return (
    <>
      {samuneImage.map((image, index) => (
        <div className="shadow-inner p-1 transform hover:scale-105 transition-transform duration-300 object-contain text-center">
          <img
            key={index}
            src={image}
            alt={`samuneImage ${index}`}
            width="576px"
            height="1024px"
            className=" aspect-ratio: 9 / 16"
            onClick={() => openModal(index)}
          />
          <button
            onClick={() => youtubeLink(index)}
            className="sm:text-2xl transform hover:scale-105 transition-transform duration-300 hover:bg-sky-200 w-full mt-2"
          >
            {name[index]}
          </button>
        </div>
      ))}

      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center"
          onClick={closeModal}
        >
          <img src={selectedImage} alt={`selectedImage`} className=" h-1/2" />
        </div>
      )}
    </>
  );
};

export default IllustArray;
