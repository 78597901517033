import React from "react";
import { Link } from "react-router-dom";

const Start = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Link to={"/Home"}>
        <div className="text-3xl text-sky-400 text-center">
          ネモた、誕生日おめでとう
        </div>
      </Link>
    </div>
  );
};

export default Start;
