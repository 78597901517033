import React from "react";
import TitleBar from "./parts/TitleBar";
import { Link } from "react-router-dom";
import YouTubeVideo from "./parts/YouTubeVideo";

const Home = () => {
  return (
    <div className=" relative h-screen   ">
      <TitleBar />
      <YouTubeVideo />

      <div className="  sm:flex pt-6 justify-center items-center ">
        <Link to={"/Illust"}>
          <IllustIcon />
        </Link>
        <Link to={"/Message"}>
          <MessageIcon />
        </Link>
        <Link to={"/TimeLine"}>
          <TLIcon />
        </Link>
      </div>
    </div>
  );
};

export default Home;

const IllustIcon = () => {
  return (
    <div className="text-3xl text-sky-400  p-8 m-2 text-center shadow transform hover:scale-105 transition-transform duration-300 ">
      イラスト
    </div>
  );
};

const MessageIcon = () => {
  return (
    <div className="text-3xl text-sky-400  p-8 m-2 text-center shadow transform hover:scale-105 transition-transform duration-300">
      メッセージ
    </div>
  );
};

const TLIcon = () => {
  return (
    <div className="text-3xl text-sky-400  p-8 m-2 text-center shadow transform hover:scale-105 transition-transform duration-300">
      年表
    </div>
  );
};
