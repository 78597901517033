import React from "react";
import Youtube from "react-youtube";

const YouTubeVideo = () => {
  //const videoId = "Ci_zad39Uhw?si=y9vaQ0zLrvmTNRHF";
  const videoId = "dtd34KfBkpQ?si=7bTo5qFk1a3chTcj";
  const opts = {
    width: "100%",
    height: "100%",

    playerVars: {
      playsinline: 0,
      autoplay: 1,
      loop: 1,
      rel: 0,
    },
  };

  return (
    <div className="flex pt-6 justify-center sm:h-3/5 ">
      <Youtube
        videoId={videoId}
        opts={opts}
        className="shadow-md  aspect-video p-2"
      />
    </div>
  );
};

export default YouTubeVideo;
